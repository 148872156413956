/**
 * Vendor
 */
import React from "react";
import { LazyMotion, m, domAnimation } from "framer-motion";
import styled from "styled-components";

/**
 * Type
 */
import type { InvestmentItemProps } from "./investments-page-index.types";
import WrapperItem from "@components/globals/wrapper/wrapper-item";
import ImageBox from "@components/motion/motion-box/image-box";
import { Svg } from "@components/svg";
import Headline from "@components/globals/type/headline";
import Box from "@components/motion/motion-box";
import { MiniPill } from "./investments-segment-control";

const ItemWrapper = styled(m.div)`
  --item-height: 8.125rem;
  --outer-space: 0.25rem;
  width: 100%;
  padding: 1.25rem 0.25rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: solid 2px ${({ theme }) => theme.colors.black};
  will-change: transform;

  &:hover,
  &:hover p {
    text-decoration: none;
  }

  .click-box {
    position: absolute;
    /* background: red; */
    inset: 0;

    &:focus-visible:after {
      border-radius: var(--item-height);
    }
  }

  .icon-wrap {
    margin-left: 1rem;
    pointer-events: none;

    svg {
      pointer-events: none;
    }
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    --item-height: 10.125rem;
    --outer-space: calc(var(--rail-inner) + var(--column-gap));
    align-items: center;
    min-height: var(--item-height);
    padding: 2rem var(--outer-space);
    border-radius: var(--item-height);
    box-shadow: inset 0px 0px 0px var(--border-width) var(--border-color);
    border-bottom: none;
  }

  .brand-sentence {
    margin: 0;
    margin-top: 0.75rem;
  }

  .item-inner {
    display: flex;
    align-items: start;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.size.laptop}) {
      align-items: center;
      flex-direction: row;
    }
  }

  &[data-view="laptop"],
  *[data-view="laptop"] {
    display: none;
    visibility: none;
    pointer-events: none;
  }

  &[data-view="mobile"],
  *[data-view="mobile"] {
    display: inherit;
    visibility: visible;
    pointer-events: auto;

    ${MiniPill} {
      margin-right: 0.6rem;
    }
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    &[data-view="laptop"],
    *[data-view="laptop"] {
      display: inherit;
      visibility: visible;
      pointer-events: auto;
    }

    &[data-view="mobile"],
    *[data-view="mobile"] {
      display: none;
      visibility: none;
      pointer-events: none;
    }
  }

  .content-bar {
    width: 100%;
    ${MiniPill} {
      margin-top: 1rem;
      margin-right: 0.6rem;
      margin-left: -0.2rem;
    }
  }

  .tag-bar {
    position: absolute;
    right: var(--outer-space);

    ${MiniPill} {
      margin-right: 0.6rem;
    }
  }

  .item-logo {
    width: calc(26% + 2rem);
    position: relative;

    @media (min-width: ${(props) => props.theme.size.tablet}) {
      width: calc(20% + 2rem);
    }

    @media (min-width: ${(props) => props.theme.size.tabletLg}) {
      width: calc(22% + 2rem);
    }

    @media (min-width: ${(props) => props.theme.size.laptopLg}) {
      width: calc(26% + 2rem);
    }

    img {
      object-fit: contain !important;
      object-position: left;
      opacity: 1;
      width: calc(var(--img-width) * 0.4);
      height: calc(var(--img-height) * 0.4);

      @media (min-width: ${(props) => props.theme.size.tablet}) {
        width: calc(var(--img-width) * 0.35);
        height: calc(var(--img-height) * 0.35);
      }

      @media (min-width: ${(props) => props.theme.size.tabletLg}) {
        width: calc(var(--img-width) * 0.5);
        height: calc(var(--img-height) * 0.5);
      }

      @media (min-width: ${(props) => props.theme.size.laptopLg}) {
        width: calc(var(--img-width) * 0.65);
        height: calc(var(--img-height) * 0.65);
      }
    }
  }
`;

/**
 * InvestmentAllItem
 */
function InvestmentAllItem({
  brand_name,
  brand_sentence,
  brand_url,
  category,
  investment_type,
  logo_asset,
  setCategory,
}: InvestmentItemProps) {
  const initialExit = {
    "--border-color": "rgba(255, 255, 255, 1)",
    "--border-width": "0",
    "--content-bar-opacity": 0,
    "--gradient-alpha": 0.8,
    opacity: 0,
  } as any;

  const viewportAnimate = {
    opacity: 1,
  } as any;

  const animate = {
    "--border-color": "rgba(38, 36, 31, 1)",
    "--border-width": "2px",
    "--content-bar-opacity": 1,
    "--gradient-alpha": 1,
    scale: 1.01,
  } as any;

  return (
    <LazyMotion features={domAnimation}>
      <WrapperItem
        options={{ colStart: "start-rail", colEnd: "end-rail" }}
        mdOptions={{ colStart: "start-outer", colEnd: "end-outer" }}
      >
        <ItemWrapper
          initial={initialExit}
          whileHover={animate}
          whileInView={viewportAnimate}
          transition={{
            duration: 0.4,
            ease: [0.1, 0.25, 0.3, 1],
          }}
        >
          <a className="click-box" href={brand_url.url} target="_blank" />
          <div className="item-inner content-bar">
            <ImageBox
              className="item-logo"
              image={logo_asset}
              alt={`${brand_name} logo`}
              hiddenStyles={{
                y: 0,
              }}
              style={
                {
                  "--img-width": `${logo_asset?.gatsbyImageData?.width}px`,
                  "--img-height": `${logo_asset?.gatsbyImageData?.height}px`,
                } as any
              }
            />
            <Headline
              className="brand-sentence"
              size="headline5"
              chLength={16}
              text={brand_sentence}
            />
            <span data-view="mobile">
              <MiniPill
                onClick={() => setCategory(category)}
                className="clickable"
                data-color={category}
              >
                {category}
              </MiniPill>
              <MiniPill
                aria-hidden="true"
                tabIndex={-1}
                data-color={"investment"}
              >
                {investment_type}
              </MiniPill>
            </span>
          </div>
          <div className="item-inner tag-bar">
            <span data-view="laptop">
              <MiniPill
                onClick={() => setCategory(category)}
                className="clickable"
                data-color={category}
              >
                {category}
              </MiniPill>
              <MiniPill
                aria-hidden="true"
                tabIndex={-1}
                data-color={"investment"}
              >
                {investment_type}
              </MiniPill>
            </span>
            <Svg svg="ExternalLinkArrow" />
          </div>
        </ItemWrapper>
      </WrapperItem>
    </LazyMotion>
  );
}

export default InvestmentAllItem;
