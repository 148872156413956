import React from "react";
import styled from "styled-components";

/**
 * Components
 */
import { MasonryGrid } from "@components/masonry-grid";
import WrapperItem from "@globals/wrapper/wrapper-item";

/**
 * Local Directory
 */
import InvestmentItem from "./investments-featured-item";

/**
 * Types
 */
import type { InvestmentsPageIndexProps } from "./investments-page-index.types";

const MasonryWrapper = styled(MasonryGrid)`
  margin-top: 4vw;
`;
export default function InvestmentFeaturedLayout({
  items,
}: InvestmentsPageIndexProps) {
  return (
    <WrapperItem
      options={{ colStart: "start-rail", colEnd: "end-rail" }}
      mdOptions={{ colStart: "start-outer", colEnd: "end-outer" }}
    >
      <MasonryWrapper
        columnsCountBreakPoints={{ 300: 1, 768: 2 }}
        gap={"var(--column-gap)"}
      >
        {items?.map(
          (
            {
              investment_index: {
                document: { data, id },
              },
            }: any,
            index: number
          ) => {
            return <InvestmentItem key={id} index={index} {...data} />;
          }
        )}
      </MasonryWrapper>
    </WrapperItem>
  );
}
