import React from "react";
import { LazyMotion, m, domAnimation } from "framer-motion";
import styled from "styled-components";

/**
 * Components
 */
import Headline from "@components/globals/type/headline";
import ImageBox from "@components/motion/motion-box/image-box";
import { Svg } from "@components/svg";

/**
 * Types
 */
import type { InvestmentItemProps } from "./investments-page-index.types";
import { useViewportChange } from "@functions/useViewportChange";
import { device } from "@style/theme";
import { useHasMounted } from "@components/helpers/client-only";
import { useViewportMedia } from "@functions/useViewportMedia";
import useHoverCheck from "src/hooks/use-hover-check";

const InvestmentCard = styled(m.a)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vw;

  &:hover {
    text-decoration: none;
  }

  &[data-fourth="true"],
  &[data-third="true"] {
    .image-container {
      aspect-ratio: 4 / 3;
    }
  }

  .brand-sentence {
    padding-top: 1rem;
    padding-left: 0.4em;

    @media (min-width: ${(props) => props.theme.size.laptop}) {
      --ch-length: 15ch;
    }
  }

  .image-container {
    aspect-ratio: 7 / 8;
    overflow: hidden;
    border-radius: var(--border-radius, 3rem);
    box-shadow: 0px 0px 0px var(--border-width) var(--border-color);
    position: relative;
    box-sizing: border-box;
    transition: 0.25s ${({ theme }) => theme.animation.timingFunction.css};
    transition-properties: border-radius, box-shadow;
    will-change: border-radius, box-shadow;

    .feature-image img {
      overflow: hidden;
      border-radius: calc(var(--border-radius, 3rem) - 2px);
      transition: 0.25s ${({ theme }) => theme.animation.timingFunction.css};
      transition-properties: border-radius, box-shadow;
      will-change: border-radius, box-shadow;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60%;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, var(--gradient-alpha)) 100%
      );
    }

    .content-bar {
      opacity: var(--content-bar-opacity);
      transform: translateY(var(--content-bar-y, 1rem));
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: end;
      bottom: 0;
      width: 100%;
      z-index: 2;
      padding: 6vw;

      @media (min-width: ${(props) => props.theme.size.tablet}) {
        padding: 4vw;
      }

      @media (min-width: ${(props) => props.theme.size.laptopLg}) {
        padding: 3vw;
      }
    }

    .item-logo {
      img {
        object-fit: contain;
        transform-origin: left bottom;
        transform: scale(0.6);
      }
    }

    .image-box {
      height: 100%;

      img {
        filter: var(--filter);
        transition-properties: transform, filter;
      }
    }
  }
`;

export default function InvestmentItem({
  index,
  brand_name,
  brand_sentence,
  brand_url,
  feature_image,
  logo_asset,
  id,
}: InvestmentItemProps) {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  const canHover = useHoverCheck();

  const isEven = index % 2 === 0;
  const isOdd = !isEven;
  const isThrid = index % 3 === 0 && index !== 0;
  const isFourth = (index - 1) % 5 === 0 && index !== 0;

  const initialExit = {
    "--border-color": "rgba(255, 255, 255, 1)",
    "--border-width": "0",
    "--content-bar-opacity": 0,
    "--gradient-alpha": 0.8,
    "--content-bar-y": 8,
    "--filter": "grayscale(100%)",
  } as any;

  const animate = {
    "--border-color": "rgba(38, 36, 31, 1)",
    "--border-width": "2px",
    "--content-bar-opacity": 1,
    "--gradient-alpha": 1,
    "--content-bar-y": 0,
    "--filter": "grayscale(0%)",
    scale: 1.01,
  } as any;

  return (
    <LazyMotion features={domAnimation}>
      <InvestmentCard
        key={id}
        data-even={isEven}
        data-odd={isOdd}
        data-third={isThrid}
        data-fourth={isFourth}
        href={brand_url.url}
        target={brand_url.target}
      >
        <m.div
          className="image-container"
          initial={initialExit}
          whileInView={!canHover ? animate : null}
          whileHover={animate}
          viewport={{
            margin: "5% 0px -5% 0px",
            amount: 0.85,
          }}
          transition={{
            duration: 0.4,
            ease: [0.1, 0.25, 0.3, 1],
          }}
        >
          <div className="content-bar">
            <ImageBox
              className="item-logo"
              image={logo_asset}
              alt={`${brand_name} logo`}
            />
            <Svg svg="ExternalLinkArrow" />
          </div>

          <ImageBox
            className="image-box feature-image"
            hiddenStyles={{ y: 0, scale: 1.2 }}
            image={feature_image}
            alt=""
          />
        </m.div>

        <Headline
          className="brand-sentence"
          size="headline4"
          chLength={null}
          text={brand_sentence}
        />
      </InvestmentCard>
    </LazyMotion>
  );
}
