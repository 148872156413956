import React, { useState } from "react";
import styled from "styled-components";

/**
 * Components
 */
import WrapperItem from "@globals/wrapper/wrapper-item";
import InvestmentAllItem from "./investments-all-item";

/**
 * Types
 */
import type { InvestmentsPageIndexProps } from "./investments-page-index.types";
import useFilterCategoryData from "../../../hooks/use-filter-category-data";
import useFilterTypeData from "../../../hooks/use-filter-type-data";

/**
 * InvestmentAllLayout
 */
export default function InvestmentAllLayout({
  allItems,
  category,
  setCategory,
}: InvestmentsPageIndexProps) {
  return (
    <>
      {allItems?.map(({ node: { data } }: any) => {
        const categoryMap = data.category;

        const categoryCondition =
          categoryMap.includes(category) || category === "all";

        if (categoryCondition) {
          return <InvestmentAllItem setCategory={setCategory} {...data} />;
        } else return null;
      })}
    </>
  );
}
