import React, { useEffect, useState } from "react";
import {
  getQueryParams,
  useQueryParamString,
} from "react-use-query-param-string";
import Wrapper from "@globals/wrapper/wrapper";

/**
 * Type
 */
import type { InvestmentsPageIndexProps } from "./investments-page-index.types";
import InvestmentFeaturedLayout from "./investments-featured-layout";
import InvestmentAllLayout from "./investments-all-layout";
import InvestmentSegmentControl, {
  MiniPill,
} from "./investments-segment-control";
import styled from "styled-components";
import useFilterCategoryData from "src/hooks/use-filter-category-data";
import useFilterTypeData from "src/hooks/use-filter-type-data";
import WrapperItem from "@components/globals/wrapper/wrapper-item";
import Text from "@components/globals/type/text";

export type InvestmentLists = "featured" | "all";

const IndexWrapper = styled(Wrapper)`
  margin-top: 2vw;

  &[data-list="all"] {
    grid-row-gap: 1vw;
  }
`;

const ClearItem = styled(WrapperItem)`
  align-items: center;
  text-align: center;
  padding: 8vw 0;
`;

const ClearButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;

  font-size: 1rem;
  font-family: inherit;
  text-transform: inherit;
  text-decoration: underline;
`;

/**
 * InvestmentsPageIndex Component
 */
export default function InvestmentsPageIndex({
  content,
  items,
  allItems,
}: InvestmentsPageIndexProps) {
  const categoryData = useFilterCategoryData();
  const [category, setCategory] = useState("all");
  const [categoryParam, setCategoryParam] = useQueryParamString("category", "");

  const [listState, setListState] = useState<InvestmentLists>("featured");
  const [listParam, setListParam] = useQueryParamString("list", "");

  useEffect(() => {
    const params = getQueryParams();
    if (params?.list) {
      setListState(params?.list as InvestmentLists);
    }
    if (params?.category) {
      setCategory(params?.category as any);
    }
  }, [categoryParam, setCategoryParam, listParam, setListParam]);

  return (
    <>
      <Wrapper>
        <InvestmentSegmentControl
          listState={listState}
          setListState={setListParam}
          category={category}
          categoryData={categoryData}
          setCategory={setCategoryParam}
        />
      </Wrapper>
      <IndexWrapper data-list={listState}>
        {listState === "featured" && <InvestmentFeaturedLayout items={items} />}
        {listState === "all" && (
          <InvestmentAllLayout
            allItems={allItems}
            setCategory={setCategoryParam}
            category={category}
          />
        )}
      </IndexWrapper>
      <Wrapper>
        {category !== "all" && (
          <ClearItem>
            <Text chLength={null}>
              showing filters for{" "}
              <MiniPill
                onClick={() => setCategory(category)}
                className="clickable"
                data-color={category}
              >
                {category}
              </MiniPill>{" "}
              <ClearButton onClick={() => setCategoryParam("all")}>
                Clear filter
              </ClearButton>
            </Text>
          </ClearItem>
        )}
      </Wrapper>
    </>
  );
}
