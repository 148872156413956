import React from "react";
import { graphql } from "gatsby";

import { PageHero } from "../components/page-hero";
import { getSlice } from "../functions/util";
import { InvestmentsPageIndex } from "../components/investments-page-index";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    allPrismicInvestment: { edges },
    prismicPage: {
      data: { body },
    },
  },
}) {
  const heroSlice = getSlice(body, "hero");
  const investmentIndex = getSlice(body, "investment_index");

  return (
    <>
      {heroSlice && <PageHero content={heroSlice?.primary} />}
      {investmentIndex && (
        <InvestmentsPageIndex
          content={investmentIndex?.primary}
          items={investmentIndex?.items}
          allItems={edges}
        />
      )}
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PrismicPage($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        description
        title
        body {
          ... on PrismicPageDataBodyHero {
            id
            primary {
              headline
            }
            slice_type
          }
          ... on PrismicPageDataBodyInvestmentIndex {
            id
            primary {
              featured_label
              all_investments_label
            }
            items {
              investment_index {
                document {
                  ... on PrismicInvestment {
                    id
                    data {
                      brand_name {
                        text
                      }
                      brand_sentence
                      brand_url {
                        target
                        url
                      }
                      category
                      feature_image {
                        gatsbyImageData(
                          srcSetMaxWidth: 3000
                          placeholderImgixParams: { blur: 1600, px: 1 }
                          imgixParams: { q: 50 }
                        )
                        alt
                      }
                      investment_type
                      logo_asset {
                        gatsbyImageData(
                          srcSetMaxWidth: 200
                          placeholderImgixParams: { blur: 1600, px: 1 }
                          imgixParams: { q: 50 }
                        )
                        alt
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
        }
        share_image {
          url
        }
      }
    }

    allPrismicInvestment(
      sort: { fields: data___brand_name___text, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            brand_name {
              text
            }
            brand_sentence
            brand_url {
              target
              url
            }
            category
            investment_type
            logo_asset {
              gatsbyImageData(
                srcSetMaxWidth: 200
                placeholderImgixParams: { blur: 1600, px: 1 }
                imgixParams: { q: 50 }
              )
              alt
              thumbnails {
                thumb {
                  alt
                  copyright
                  url
                  gatsbyImageData(srcSetMaxWidth: 200)
                }
              }
            }
          }
        }
      }
    }
  }
`;
