import React from "react";
import WrapperItem from "@components/globals/wrapper/wrapper-item";
import { WrapperItemProps } from "@globals/wrapper/wrapper-item";
import { motion, MotionConfig } from "framer-motion";
import styled from "styled-components";
import Text from "@components/globals/type/text";
import { InvestmentLists } from "./investments-page-index";
import Box from "@components/motion/motion-box";
import { Svg } from "@components/svg";

interface InvestmentSegmentControlProps {
  setListState: any;
  listState: InvestmentLists;
  category?: string;
  categoryData?: string[];
  setCategory?: any;
}
const SegmentWrapper = styled(Box)`
  --segment-control-height: 5.125rem;
  --segment-active-filter: blur(2rem);
  --segment-active-bkg: linear-gradient(
    90deg,
    #dfb8c6 -177.6%,
    #efb39c -167.42%,
    #f8c782 -148.51%,
    #ffc83d -123.77%,
    #ffdd7c -103.41%,
    #cfddee -78.67%,
    #bde79a -53.94%,
    #bfed6f -30.66%,
    #d7d854 -5.93%,
    #ffc83d 9.55%,
    #f1de71 46.44%,
    #c2eba9 85.75%,
    #cfddee 101.73%
  );
  width: 100%;
  height: var(--segment-control-height);
  border-radius: var(--segment-control-height);
  margin-top: 2vw;
  border: solid 2px ${({ theme }) => theme.colors.black};
  position: relative;
  display: inline-flex;

  @media (min-width: ${(props) => props.theme.size.laptopLg}) {
    --segment-control-height: 6.125rem;
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ theme }) => theme.colors.white};
    border-radius: var(--segment-control-height);
    z-index: -2;
  }
`;

const SegmentButton = styled(motion.button)`
  height: 100%;
  appearance: none;
  border: none;
  border-radius: calc(var(--segment-control-height) / 2);
  background: none;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center !important;
  padding: 0 3rem;

  &.featured-inner {
    width: var(--featured-width);
  }

  &.all-inner {
    width: var(--all-width);
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    justify-content: center !important;

    &.all-inner[data-active="true"] {
      justify-content: space-between !important;
    }
  }

  &,
  span {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ActiveBar = styled(motion.span)`
  position: absolute;
  inset: 0;

  &:before {
    content: "";
    position: absolute;
    inset: -2px;
    border: solid 2px ${({ theme }) => theme.colors.black};
    border-radius: var(--segment-control-height);
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    inset: -1rem;
    background: var(--segment-active-bkg);
    filter: var(--segment-active-filter);
    border-radius: var(--segment-control-height);
    z-index: -1;
  }
`;

const TagBar = styled.span`
  position: relative;
  z-index: 2;
  font-family: "GTHaptikMedium", sans-serif;
  font-weight: 400;
  text-transform: lowercase;
  font-size: ${(props) => props.theme.fonts.body};
  line-height: 130%;

  &[data-view="laptop"] {
    display: none;
    visibility: none;
    pointer-events: none;
  }

  &[data-view="mobile"] {
    display: inline-flex;
    visibility: visible;
    pointer-events: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 2vw 0;
  }

  @media (min-width: ${(props) => props.theme.size.laptop}) {
    &[data-view="laptop"] {
      display: inline-block;
      visibility: visible;
      pointer-events: auto;
    }

    &[data-view="mobile"] {
      display: none;
      visibility: none;
      pointer-events: none;
    }
  }

  p {
    margin: 0;
  }

  select {
    appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    background: transparent;
    color: transparent;
    position: absolute;
    inset: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: solid 3px black;
      border-radius: 4px;
    }
  }
`;

export const MiniPill = styled.button`
  font-size: ${(props) => props.theme.fonts.h6};
  padding: 0.2rem 0.6rem 0.3rem;
  border-radius: 1em;
  background: ${(props) => props.theme.colors.warmWhite};
  color: ${(props) => props.theme.colors.black} !important;
  margin: 0 0.25rem;
  font-family: "GTHaptikMedium", sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 2;

  &:disabled {
    opacity: 1;
  }

  &.clickable {
    transition-property: transform;
    transition: 0.75s ${({ theme }) => theme.animation.timingFunction.css};
    cursor: alias;

    &:hover {
      transform: scale(1.05);
    }
  }

  &[data-color="health"] {
    background: ${(props) => props.theme.colors.health};
  }

  &[data-color="environment"] {
    background: ${(props) => props.theme.colors.environment};
  }

  &[data-color="food"] {
    background: ${(props) => props.theme.colors.food};
  }

  &[data-color="money"] {
    background: ${(props) => props.theme.colors.money};
  }

  &[data-color="community"] {
    background: ${(props) => props.theme.colors.community};
  }

  &[data-color="media"] {
    background: ${(props) => props.theme.colors.media};
  }

  &[data-color="commerce"] {
    background: ${(props) => props.theme.colors.commerce};
  }

  &[data-color="investment"] {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 0px 2px inset rgba(38, 36, 31, 0.1);
  }
`;

const FauxSelect = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;

  ${MiniPill} {
    pointer-events: none;
  }
`;

const Select = ({ values, callback, selected }: any) => {
  return (
    <select
      defaultValue={selected}
      onChange={({ target: { value } }) => callback(value)}
    >
      <option value="all">all</option>
      {values.map(({ value, text }: any) => (
        <option value={value}>{text}</option>
      ))}
    </select>
  );
};

const SelectControl = ({
  view,
  allActive,
  category,
  setCategory,
  categoryData,
}: any) => {
  return (
    <>
      {allActive && (
        <TagBar data-view={view}>
          <label>Category:</label>{" "}
          <FauxSelect>
            <Select
              selected={category}
              values={categoryData.map((item: string) => {
                return { value: item, text: item };
              })}
              callback={setCategory}
            />
            <MiniPill
              aria-hidden="true"
              tabIndex={-1}
              className="visible-text"
              data-color={category}
            >
              {category}
            </MiniPill>{" "}
            <Svg svg="Caret" />
          </FauxSelect>
        </TagBar>
      )}
    </>
  );
};
/**
 * InvestmentSegmentControl
 */
function InvestmentSegmentControl({
  setListState,
  listState,
  category,
  categoryData,
  setCategory,
}: InvestmentSegmentControlProps) {
  const featActive = listState === "featured";
  const allActive = listState === "all";

  const gridOptions = {
    options: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
    mdOptions: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
  } as WrapperItemProps;

  const featuredMotionProps = {
    initial: {
      "--featured-width": "50%",
    },
    animate: {
      "--featured-width": allActive ? "26%" : "50%",
    },
  } as any;

  const allMotionProps = {
    initial: {
      "--all-width": "50%",
    },
    animate: {
      "--all-width": allActive ? "84%" : "50%",
      justifyContent: allActive ? "space-between" : "center",
    },
  } as any;

  return (
    <MotionConfig
      transition={{
        duration: 0.25,
        ease: [0.1, 0.25, 0.3, 1],
      }}
    >
      <WrapperItem {...gridOptions}>
        <SegmentWrapper data-segment={listState}>
          <SegmentButton
            className="featured-inner"
            data-active={featActive}
            onClick={() => setListState("featured")}
            {...featuredMotionProps}
          >
            <Text as="span" size="bodyLarge">
              Featured
            </Text>
            {featActive && <ActiveBar layoutId="active-bar" />}
          </SegmentButton>
          <SegmentButton
            className="all-inner"
            data-active={allActive}
            onClick={() => setListState("all")}
            {...allMotionProps}
          >
            <Text as="span" size="bodyLarge">
              All Investments
            </Text>
            {allActive && <ActiveBar layoutId="active-bar" />}
            <SelectControl
              view={"laptop"}
              allActive={allActive}
              category={category}
              setCategory={setCategory}
              categoryData={categoryData}
            />
          </SegmentButton>
        </SegmentWrapper>
        <SelectControl
          view={"mobile"}
          allActive={allActive}
          category={category}
          setCategory={setCategory}
          categoryData={categoryData}
        />
      </WrapperItem>
    </MotionConfig>
  );
}

export default InvestmentSegmentControl;
