import { useStaticQuery, graphql } from "gatsby";

const useFilterCategoryData = () => {
  const data = useStaticQuery(
    graphql`
      query QueryCategory {
        allPrismicInvestment {
          distinct(field: data___category)
        }
      }
    `
  );

  return data.allPrismicInvestment.distinct;
};

export default useFilterCategoryData;
