import React, { useState } from "react";
import styled from "styled-components";

/**
 * Local Styles/JS
 */
import Wrapper from "../../globals/wrapper/wrapper";
import WrapperItem, {
  WrapperItemProps,
} from "../../globals/wrapper/wrapper-item";
import Headline from "../../globals/type/headline";

/**
 * Types
 */
import type { PageHeroProps } from "./page-hero.types";
import MotionScrollTransform from "@components/motion/motion-scroll-transform";
import { m, useTransform } from "framer-motion";

const PageHeaderWrapper = styled(m.div)`
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  overflow: visible;
  flex-direction: column;
  padding-top: 4vw;
  perspective: 1000px;
`;

/**
 * Hero Component
 */
export default function PageHero({ content }: PageHeroProps) {
  const wrapperProps = {
    options: {
      colStart: "start-rail",
      colEnd: "end-rail",
    },
    mdOptions: {
      colStart: "start-outer",
      colEnd: "end-outer",
    },
  } as WrapperItemProps;

  return (
    <Wrapper>
      <WrapperItem {...wrapperProps}>
        {content && (
          <MotionScrollTransform id="page-header" smooth={false}>
            {({ yTransformOuter }) => (
              <PageHeaderWrapper
                style={
                  {
                    opacity: useTransform(yTransformOuter, [0, 0.75], [1, 0]),
                    rotateX: useTransform(yTransformOuter, [0, 0.75], [0, 20]),
                    y: useTransform(
                      yTransformOuter,
                      [0, 0.75],
                      ["0rem", "-2rem"]
                    ),
                  } as any
                }
              >
                <Headline
                  text={content?.headline}
                  chLength={22}
                  size="headline3"
                  enableMotion={true}
                />
              </PageHeaderWrapper>
            )}
          </MotionScrollTransform>
        )}
      </WrapperItem>
    </Wrapper>
  );
}
